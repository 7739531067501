import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useState } from 'react';

export const FilterPanel = ({ filters, onFilterChange }) => {
  const [providerTypeExpanded, setProviderTypeExpanded] = useState(true);
  const [connectionExpanded, setConnectionExpanded] = useState(true);

  const handleCheckboxChange = (key, option) => {
    onFilterChange(key, option);
  };

  const StyledAccordion = styled(Accordion)({
    width: 175,
    marginRight: 2,
  });

  return (
    <Box sx={{ flexDirection: 'column', marginTop: 2.5, marginRight: 1 }}>
      <Typography
        variant='subtitle2'
        fontWeight={'bold'}
        sx={{ marginBottom: 3 }}
      >
        Filters
      </Typography>
      <StyledAccordion
        expanded={providerTypeExpanded}
        onChange={() => setProviderTypeExpanded(!providerTypeExpanded)}
        sx={{
          marginTop: 5,
        }}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography>Provider Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {['online', 'offline', 'file'].map((type) => (
              <FormControlLabel
                key={type}
                control={
                  <Checkbox
                    checked={filters['ProviderType']?.includes(type)}
                    onChange={() => handleCheckboxChange('ProviderType', type)}
                  />
                }
                label={`${type.charAt(0).toUpperCase() + type.slice(1)} Store`}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </StyledAccordion>
      <StyledAccordion
        expanded={connectionExpanded}
        onChange={() => setConnectionExpanded(!connectionExpanded)}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography>Connection</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {['connected', 'disconnected'].map((status) => (
              <FormControlLabel
                key={status}
                control={
                  <Checkbox
                    checked={filters['Status']?.includes(status)}
                    onChange={() => handleCheckboxChange('Status', status)}
                  />
                }
                label={status.charAt(0).toUpperCase() + status.slice(1)}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default FilterPanel;
