import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useState } from 'react';

const STATUS_OPTIONS = [
  { key: 'No Status', value: 'NO_STATUS' },
  { key: 'Created', value: 'CREATED' },
  { key: 'Pending', value: 'PENDING' },
  { key: 'Ready', value: 'READY' },
  { key: 'Failed', value: 'FAILED' },
  { key: 'Running', value: 'RUNNING' },
  { key: 'Cancelled', value: 'CANCELLED' },
];

const ENTER_KEY = 'Enter';
const filterItemTxtSize = '0.875rem';

const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: filterItemTxtSize,
    marginBottom: 0,
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '120px',
  },
});

export const FilterPanel = ({
  filters,
  tags = [],
  owners = [],
  onCheckBoxChange = () => null,
  onTextFieldEnter = () => null,
}) => {
  const [statusesExpanded, setStatusesExpanded] = useState(true);
  const [tagsExpanded, setTagsExpanded] = useState(false);
  const [ownerExpanded, setOwnerExpanded] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');

  const StyledAccordion = styled(Accordion)({
    width: 170,
    marginRight: 2,
  });

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);

    if (event.target.value === '') {
      onTextFieldEnter('');
    }
  };

  const handleTextFieldKeyPress = (event) => {
    if (event.key === ENTER_KEY) {
      onTextFieldEnter(textFieldValue);
    }
  };

  return (
    <Box sx={{ flexDirection: 'column', marginTop: 2.5, marginRight: 1 }}>
      <Typography variant='subtitle2' fontWeight='bold'>
        Filters
      </Typography>
      <TextField
        fullWidth
        label='Name'
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        value={textFieldValue}
        onChange={handleTextFieldChange}
        onKeyDown={handleTextFieldKeyPress}
        sx={{
          marginTop: 3,
          width: 170,
          fontSize: filterItemTxtSize,
        }}
        size='small'
      />

      <StyledAccordion
        expanded={statusesExpanded}
        onChange={() => setStatusesExpanded(!statusesExpanded)}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography sx={{ fontSize: filterItemTxtSize }}>Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {STATUS_OPTIONS.map((status) => (
              <StyledFormControlLabel
                key={status.key}
                control={
                  <Checkbox
                    checked={filters.Statuses?.includes(status.value) || false}
                    onChange={() => onCheckBoxChange('Statuses', status.value)}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                  />
                }
                label={status.key}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={ownerExpanded}
        onChange={() => setOwnerExpanded(!ownerExpanded)}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography sx={{ fontSize: filterItemTxtSize }}>Owners</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {owners.map((owner) => (
              <StyledFormControlLabel
                key={owner.name}
                control={
                  <Checkbox
                    checked={filters.Owners?.includes(owner.name) || false}
                    onChange={() => onCheckBoxChange('Owners', owner.name)}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                  />
                }
                label={owner.name}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={tagsExpanded}
        onChange={() => setTagsExpanded(!tagsExpanded)}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography sx={{ fontSize: filterItemTxtSize }}>Tags</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {tags.map((tag) => (
              <StyledFormControlLabel
                key={tag.name}
                control={
                  <Checkbox
                    checked={filters.Tags?.includes(tag.name) || false}
                    onChange={() => onCheckBoxChange('Tags', tag.name)}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                  />
                }
                label={tag.name}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default FilterPanel;
