import { Box, Chip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { useRouter } from 'next/router';
import React from 'react';
import { providerLogos } from '../../../api/resources';
import { deepCopy } from '../../../helper';
import { ConnectionSvg } from '..//icons/Connections';
import { isMatchingDefault } from '../DatasetTable/DatasetTable';
import { UserBubbleSvg } from '../icons/Owner';
import NoDataMessage from '../NoDataMessage';
import { DEFAULT_RESOURCE_LIST_SIZE } from '../ResourceList';
import FilterPanel from './FilterPanel';

export const provider_columns = [
  {
    field: 'id',
    headerName: 'id',
    flex: 1,
    editable: false,
    sortable: false,
    filterable: false,
    hide: true,
  },
  {
    field: 'software',
    headerName: 'Name',
    flex: 1,
    editable: false,
    sortable: false,
    filterable: false,
    hide: false,
    renderCell: function (params) {
      const provider =
        providerLogos[params.row.software?.toUpperCase()] ??
        providerLogos['LOCALMODE'];
      return (
        <>
          <img
            alt={params.row.software}
            style={{
              width: '4.0em',
              height: '2.5em',
            }}
            src={provider}
          />
          <Typography variant='body2' sx={{ marginLeft: 1 }}>
            <strong>{params.row.name}</strong>
          </Typography>
        </>
      );
    },
  },
  {
    field: 'provider-type',
    headerName: 'Type',
    flex: 1,
    maxWidth: 175,
    editable: false,
    sortable: false,
    filterable: false,
    renderCell: function (params) {
      const typeMap = {
        online: { path: 'static/online_store.svg', text: 'Online Store' },
        offline: { path: 'static/offline_store.svg', text: 'Offline Store' },
        file: { path: 'static/file_store.svg', text: 'File Store' },
      };

      let result = typeMap.file;
      let providerType = params?.row?.['provider-type'];

      if (providerType && typeof providerType === 'string') {
        providerType = providerType.toLocaleLowerCase();
        if (providerType.includes('online')) {
          result = typeMap.online;
        } else if (providerType.includes('offline')) {
          result = typeMap.offline;
        }
      }

      return (
        <>
          <img
            style={{
              width: '2em',
              height: '1.5em',
            }}
            src={result.path}
          />
          <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
            {result.text}
          </Typography>
        </>
      );
    },
  },
  {
    field: 'tags',
    headerName: 'Tags',
    flex: 1,
    width: 350,
    editable: false,
    sortable: false,
    filterable: false,
    hide: true,
    renderCell: function (params) {
      return (
        <>
          <Box>
            {params.row?.tags?.slice(0, 3).map((tag) => (
              <Chip
                label={tag}
                key={tag}
                data-testid={tag + 'id'}
                sx={{
                  margin: '0.1em',
                  border: '1px solid #F2BB51',
                  color: '#F2BB51',
                  cursor: 'pointer',
                }}
                variant='outlined'
              />
            ))}
          </Box>
        </>
      );
    },
  },
  {
    field: 'sources',
    headerName: 'Owner',
    flex: 1,
    width: 450,
    editable: false,
    sortable: false,
    filterable: false,
    renderCell: function (params) {
      const [latestResource, numberOfResources] = getLatestSource(
        params.row?.sources
      );

      let latestOwner = '---';
      let count = 0;

      if (latestResource) {
        latestOwner = latestResource?.owner;
        count = numberOfResources;
      }

      return (
        <div style={{ display: 'flex' }}>
          <div style={{ marginTop: 10 }}>
            <UserBubbleSvg
              height='30'
              width='30'
              letter={latestOwner[0]?.toUpperCase()}
            />
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
                {latestOwner}
              </Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <Typography variant='body2' sx={{ marginLeft: 0.5 }}>
                # of Resources: {count}
              </Typography>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    width: 350,
    editable: false,
    sortable: false,
    filterable: false,
    renderCell: function (params) {
      // status
      const fileStores = [
        'MEMORY',
        'LOCAL_FILESYSTEM',
        'AZURE',
        'GLUE',
        'S3',
        'GCS',
        'HDFS',
      ];
      const connectedFill = '#6DDE6A';
      let result = '#DA1E28';
      if (params?.row?.status === 'READY') {
        result = connectedFill;
      } else if (
        params?.row?.status === 'CREATED' &&
        fileStores.includes(params?.row?.['provider-type'])
      ) {
        result = connectedFill;
      }

      //connection
      const latestReady = getLatestReadySource(params?.row?.sources);
      const latestConnection = latestReady?.created
        ? new Date(latestReady.created)
        : '---';

      return (
        <div>
          <div style={{ display: 'flex' }}>
            <ConnectionSvg fill={result} height='20' width='20' />
            <Typography variant='body2' sx={{ marginLeft: 1 }}>
              Status: {result === connectedFill ? 'Connected' : 'Disconnected'}
            </Typography>
          </div>
          <div style={{ display: 'flex' }}>
            <Typography variant='body2' sx={{ marginLeft: 1 }}>
              Last Connection: {latestConnection?.toLocaleString()}
            </Typography>
          </div>
        </div>
      );
    },
  },
];

function getLatestSource(sources = null) {
  let latestSource = null;
  let count = 0;

  if (!sources) {
    return [latestSource, count];
  }

  try {
    Object.keys(sources).forEach((key) => {
      sources[key]?.forEach((source) => {
        count++;
        if (
          !latestSource ||
          new Date(source.created) > new Date(latestSource.created)
        ) {
          latestSource = source;
        }
      });
    });
    return [latestSource, count];
  } catch (error) {
    console.error(error);
    return [latestSource, count];
  }
}

function getLatestReadySource(sources) {
  let latestSource = null;

  if (!sources) {
    return latestSource;
  }

  try {
    Object.keys(sources).forEach((key) => {
      sources[key]?.forEach((source) => {
        if (source.status === 'READY') {
          if (
            !latestSource ||
            new Date(source.created) > new Date(latestSource.created)
          ) {
            latestSource = source;
          }
        }
      });
    });
    return latestSource;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const MainContainer = styled(Box)({
  display: 'flex',
});

const GridContainer = styled(Box)({
  width: '100%',
});

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-colCellWrapper': {
    display: 'none',
  },
  marginBottom: '1.5em',
  cursor: 'pointer',
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
}));

export const provider_filters = Object.freeze({
  ProviderType: [], //'online', 'offline', 'file'
  Status: [], //'connected', 'disconnected'
});

export const ProviderTable = ({
  resources,
  type,
  loading,
  count,
  currentPage,
  setPage,
  currentFilter,
  setFilter,
}) => {
  let router = useRouter();
  const rows = resources ?? [];

  const redirect = (name = '') => {
    router.push(`/providers/${name}`);
  };

  const noDataHeight = 300;

  const isEmpty = () => {
    return !loading && rows?.length === 0;
  };

  const checkboxMap = deepCopy({
    ...provider_filters,
    ...(currentFilter ?? {}),
  });

  const addToFilter = (key, option) => {
    const updateMap = { ...checkboxMap };
    const list = updateMap[key];
    const index = list.indexOf(option);

    if (index === -1) {
      list.push(option);
    } else {
      list.splice(index, 1);
    }

    setFilter(type, deepCopy(updateMap));
  };

  return (
    <>
      <MainContainer>
        <FilterPanel filters={checkboxMap} onFilterChange={addToFilter} />
        <GridContainer>
          <h3>{'Providers'}</h3>
          {loading ? (
            <div data-testid='loadingGrid'>
              <StyledDataGrid
                disableVirtualization
                aria-label={'Provider'}
                autoHeight
                density='compact'
                loading={loading}
                rows={[]}
                rowCount={0}
                columns={provider_columns}
                hideFooterSelectedRowCount
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                paginationMode='server'
                rowsPerPageOptions={[DEFAULT_RESOURCE_LIST_SIZE]}
              />
            </div>
          ) : (
            <StyledDataGrid
              disableVirtualization
              autoHeight={!isEmpty()}
              sx={
                isEmpty()
                  ? {
                      minHeight: noDataHeight,
                    }
                  : {}
              }
              aria-label={'Provider'}
              rows={rows}
              columns={provider_columns}
              density='compact'
              rowHeight={80}
              hideFooterSelectedRowCount
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              paginationMode='server'
              sortModel={[{ field: 'name', sort: 'asc' }]}
              onPageChange={(newPage) => {
                setPage(type, newPage);
              }}
              components={{
                NoRowsOverlay: () => (
                  <NoDataMessage
                    type={type}
                    usingFilters={
                      !isMatchingDefault(provider_filters, checkboxMap)
                    }
                  />
                ),
              }}
              rowCount={count ?? 0}
              page={currentPage}
              pageSize={DEFAULT_RESOURCE_LIST_SIZE}
              rowsPerPageOptions={[DEFAULT_RESOURCE_LIST_SIZE]}
              onRowClick={(params, event) => {
                event?.preventDefault();
                if (params?.row?.name) {
                  redirect(params.row.name);
                }
              }}
              getRowId={(row) => row.name}
            />
          )}
        </GridContainer>
      </MainContainer>
    </>
  );
};

export default ProviderTable;
